import $ from 'jquery';
import flatpickr from "flatpickr";
import { French } from "flatpickr/dist/l10n/fr.js"

/**
 * DatePicker manager
 */
class DatePickerManager {
    constructor() {
    }

    init(options) {
        var self      = this;

        self.selectors = {
            choiceWrapper: '.date-picker__wrapper' || options.selectors.choiceWrapper,
            display: '.date-picker__display' || options.selectors.display,
            default: '[data-date-picker]' || options.selectors.default,
            complete: '.date-picker-52' || options.selectors.complete,
            day: '.day-picker' || options.selectors.complete,
            month: '.month-picker' || options.selectors.month,
            year: '.year-picker' || options.selectors.year,
            datetime: '.datetime-picker' || options.selectors.datetime,
            inputDisplay: '.form__field--display' || options.selectors.inputDisplay
        };
        self.initDatePickers();
    }

    initDatePickers() {
        var self = this;

        $(self.selectors.default).each(function (index) {
            let defaultDate = null;
            if ($(this).val()) {
                defaultDate = $(this).val();
            }

            $(this).flatpickr({
                dateFormat: 'd/m/Y',
                locale: French,
                defaultDate: defaultDate
            });
        });

      /*  console.log(self.selectors.complete);
        $(self.selectors.complete).each(function (index) {
            console.log($(this));
            let defaultDate = null;
            if ($(this).val()) {
                let defaultDate = $(this).val();
            }

            $(this).flatpickr({
                dateFormat: 'd/m/Y',
                locale: French,
                defaultDate: defaultDate
            });
        });

        $(self.selectors.day).each(function (index) {
            let defaultDate = null;
            if ($(this).val()) {
                let defaultDate = $(this).val();
            }

            $(this).flatpickr({
                dateFormat: 'd/m/Y',
                locale: French,
                defaultDate: defaultDate
            });
        });

        $(self.selectors.month).flatpickr({
            dateFormat: 'Y',
            locale: French,
            defaultDate: '2020'
        });

        $(self.selectors.year).flatpickr({
            dateFormat: 'Y',
            locale: French
        });

        $(self.selectors.datetime).flatpickr({
            dateFormat: 'd/m/Y h:i',
            locale: French,
            pickTime: true
        });*/

        return this;
    }
}

export default DatePickerManager;
