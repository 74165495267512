import $ from 'jquery';
import DatePickerManager from './components/DatePickerManager.js';
import CrudManager from './components/CrudManager.js';
import tippy from 'tippy.js';
import select2 from 'select2/dist/js/select2.min.js';


// Fonctions et variables

function sideBarCondition() {
    if ( $('.side-bar').is('.side-bar--mini') ) {
        $('.navigation__menu-element--main').removeClass('navigation__menu-element--open').css('max-height', '3rem');
        $('.navigation__menu').addClass('navigation__menu--mini').removeClass('navigation__menu--default');

        setTimeout(function() {
            $('.side-bar').css('overflow', 'visible');
            $('.navigation__menu-element').css('overflow', 'visible');
        }, 250);
    }
    else {
        $('.navigation__menu').removeClass('navigation__menu--mini').addClass('navigation__menu--default');
        $('.navigation__menu-element').css('overflow', 'hidden');

        setTimeout(function() {
            $('.side-bar').css('overflow', 'hidden');
        }, 250);
    }
}

function menuDropdownClose($initialSelector) {
    $($initialSelector).css('max-height', '3rem').removeClass('navigation__menu-element--open');
    $('.navigation__overlay').css('display', 'none');
}

function menuDropdownOpen($initialSelector, $subMenuHeightVar) {
    $initialSelector.addClass('navigation__menu-element--open').css({'max-height': $subMenuHeightVar});

    if ($initialSelector.parents('.side-bar').is('.side-bar--mini')) {
        $initialSelector.children('.navigation__overlay').css('display', 'block');
    }
}

function accountMenuClose ($navigationWrapperVar) {
    $navigationWrapperVar.removeClass('navigation--account-open').css('max-height', '3rem').children('.navigation__dropdown').removeClass('navigation__dropdown--open');
}

// Messages d'alerte

$(document).ready(function(){
    $('.alert__wrapper').delay('10000').fadeOut('slow');

    tippy('[data-tippy-content]');

    const datePickerManager = new DatePickerManager();
    datePickerManager.init();

    let crud = new CrudManager();
    crud.init();

    $('[data-date-select2]').select2({
        theme: "default"
    });

    $('[data-review]').change(function () {
        $(this).parents('form').submit();
    });

    $('[data-action-remove]').click(function () {
        let message = $(this).data('action-remove-label');
        if (message == '') {
            message = 'Êtes-vous certain de vouloir supprimer cet élément ?';
        }

        if (confirm(message)) {
            return true;
        }

        return false;
    });

    // Navigation
    $('.navigation__menu-element--main').on('click', function() {
        var subMenuHeight = $(this).children('.navigation__sub').height() + 48;

        if ($(this).is('.navigation__menu-element--open')) {
            menuDropdownClose($(this));
        }
        else {
            menuDropdownOpen($(this), subMenuHeight);
        }
    });

    $('.button--account').on('click', function() {
        var navigation        =  $(this).parent('.navigation');
        var accountDropdown   = $(this).parent('.navigation').children('.navigation__dropdown');
        var accountMenuHeight = accountDropdown.height() + 48;
        var accountOverlay    = $(this).parent('.navigation').children('.navigation__overlay');


        if ( navigation.is('.navigation--account-open') ) {
            navigation.children('.navigation__overlay').css('display', 'none');
            accountMenuClose(navigation);
        }
        else {
            navigation.css({'max-height': accountMenuHeight}).addClass('navigation--account-open');
            accountDropdown.addClass('navigation__dropdown--open');
            accountOverlay.css('display', 'block');
        }
    });

    $('.navigation__overlay').on('click', function() {
        var navigation =  $(this).parent('.navigation');

        $(this).css('display', 'none');
        accountMenuClose(navigation);
    });


    $('.button--nav-min').on('click', function() {
        $(this).parents('.layout').find('.side-bar').toggleClass('side-bar--mini');
        sideBarCondition();
    });

    // Ouverture barre de recherche

    $('.button--search-nav-min').on('click', function() {
        $(this).parent('.form__search-wrapper').find('.form__search').toggleClass('form__search--top-bar');
    });

    $('.button--search-close').on('click', function() {
        $(this).parent('.form__search-wrapper').find('.form__search').removeClass('form__search--top-bar');
    });

    // Ouverture menu mobile

    $('.button--nav-mobile').on('click', function() {
        $(this).parents('.side-bar').find('.navigation').toggleClass('navigation--open');
    });

    // Filtres

    $('.button--filter').on('click', function() {
        $(this).parent('.section__header').next('.table__filter').toggle('fast');
    });

    // Sections

    $('.button--section-hide').on('click', function() {
        $(this).parents('.section').children('.section__content').toggle('fast');
        $(this).parents('.section').children('.table').toggle('fast');
        $(this).find('.icon').toggleClass('ev-display--none');
    });

    // Élément déployable

    $('.button--table-view-more').on('click', function() {
        $(this).parents('.table__row').next('.table__row--detail').toggle('fast');
    });

    $('.list__element-title--expandable').on('click', function() {
        $('.list__element--open').not($(this).parent('.list__element')).removeClass('list__element--open').find('.list__element-content').hide('fast');
        $(this).next('.list__element-content').toggle('fast').parent('.list__element').toggleClass('list__element--open');

        return false;
    });

    // Pièces jointes

    $('#contact-attachment').change(function() {
        var input = document.getElementById('contact-attachment');
        var output = document.getElementById('contact-attachment-list');
        var children = "";

        for (var i = 0; i < input.files.length; ++i) {
            children += '<li class="form__attachment-list-element">' + input.files.item(i).name + '</li>';
        }

        output.innerHTML = '<ul class="form__attachment-list">'+children+'</ul>';
    });

    // Suppression de l'outline au focus

    $(':button').focusin(function() {
        $(this).blur();
    });

    $('[data-select-limit]').change(function () {
        const form = $(this).parent('form');
        let limit = $(this).val();
        let path = window.location.href;

        const indexOfFirst = path.indexOf('?');
        if (indexOfFirst > -1) {
             path = path.split('?')[0];
        }

        window.location.href = path + '?limit=' + limit;

        return true;
    });
});


// Layout

$(window).on('load resize', function() {
    if ($(window).width() < 1440) {
        $('.side-bar').addClass('side-bar--mini');
        sideBarCondition();
    }

    // if ($(window).width() < 800) {
    //     $('.navigation__menu').addClass('navigation__menu--mini').removeClass('navigation__menu--default');
    // }
    // else {
    //     $('.navigation__menu').removeClass('navigation__menu--mini').addClass('navigation__menu--default');
    // }

    if ($('.layout').is('.layout--ui-hide')) {
        $('.side-bar').removeClass('side-bar--mini').children('logo').toggle('fast');
    }
});

$(window).on('load', function() {
    if ($(window).width() > 1440) {
        $('.side-bar').removeClass('side-bar--mini');
    }
});
