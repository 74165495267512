
class Utils
{
    constructor()
    {

    }

    hasProperty(needle, object)
    {
        var properties = needle.split('.');

        if (object && object.hasOwnProperty(properties[0])) {
            if (properties[1] !== undefined) {
                needle = "";
                properties.forEach(function (property, index) {
                    if (index > 0 && property !== "") {
                        if (needle !== "") {
                            needle = needle + ".";
                        }
                        needle = needle + property;
                    }
                });

                return hasProperty(needle, object[properties[0]]);
            }

            return true;
        }

        return false;
}
}

export default Utils;