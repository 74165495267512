import $ from 'jquery';
import Utils from './Utils.js';
import ListManager from './ListManager.js';

/**
 * Crud builder
 *
 * @constructor
 */
class CrudManager
{
    constructor() {
        this.listManager   = new ListManager();
        this.utils = new Utils();
    }

    /**
     * Init the builder
     *
     * @param options
     */
    init(options)
    {
        var self = this;

        self.config(options);
        self.initGlobalEvents();
        self.listManager.init(options);
    }

    config(options)
    {
        this.options = {
            selection: {
                all: this.utils.hasProperty('selection.all', options) ? options.selection.all : '[data-crud-selected-all-button]',
                actions: {
                    selector: this.utils.hasProperty('selection.actions.selector', options) ? options.selection.actions.selector : '[data-crud-action-multiple]'
                }
            }

        };
    }

    initGlobalEvents()
    {
        var self = this;
        self.initFiltersEvents();
        self.initSelectionAllEvents();
        self.initActionMultipleEvents();
    }

    initFiltersEvents ()
    {
        if ($('.filter-admin-title').length > 0) {
            $('.filter-admin-title').click(function (e) {
                $('.filter-admin-body').toggle();
            });
        }
    }

    initSelectionAllEvents()
    {
        var self = this;

        $(self.options.selection.all).on('change', function () {
            var current = $(this);
            if (current.prop('checked') === true) {
                self.listManager.checkAll();
            } else {
                self.listManager.uncheckAll();
            }
            return false;
        });
    }

    initActionMultipleEvents()
    {
        var self = this;

        $(self.options.selection.actions.selector).each(function () {
            var current = $(this);
            var event   = 'click';

            if (current.hasClass('form__field--select')) {
                event = 'change';
                current.on('focus', function() {
                    this.selectedIndex = 0;
                });
            }

            current.on(event, function (e) {
                e.preventDefault();
                if (current.attr('disabled') !== undefined) {
                    return false;
                }
                var ids           = self.listManager.getItemsSelectedId();
                var items         = self.listManager.itemsSelected;
                var action        = $(this).data('action');
                var actionSuccess = $(this).data('action-success');
                var confirmLabel  = $(this).data('confirm');

                if (event === 'change') {
                    action        = $("option:selected", this).data('action');
                    actionSuccess = $("option:selected", this).data('action-success');
                    confirmLabel  = $("option:selected", this).data('confirm');
                }

                if (confirmLabel !== undefined) {
                    if (confirm(confirmLabel)) {
                        self.postAction(ids, action, actionSuccess, items, self);
                    }
                } else {
                    self.postAction(ids, action, actionSuccess, items, self);
                }

                return false;
            });
        });
    }

    postAction(ids, action, actionSuccess, items, self)
    {
        $.post(
            action,
            {
                ids: ids
            },
            function (data) {
                switch (actionSuccess) {
                    case "delete-items":
                        items.forEach(function (item, index) {
                            self.listManager.removeItem(item);
                        });
                        location.reload();
                        break;
                    default:
                        if (data && data.redirect) {
                            window.location.href = data.redirect;
                        } else {
                            location.reload();
                        }
                        break;
                }
            }
        );
    }
}

export default CrudManager;
