import $ from 'jquery';
import Utils from './Utils.js';

/**
 * List builder
 *
 * @constructor
 * @require
 */
class ListManager
{
    constructor() {
        this.itemsSelected = [];
        this.options       = {};
        this.utils = new Utils();
    }

    config(options)
    {
        this.options = {
            item: {
                selector: this.utils.hasProperty('list.item.selector', options) ? options.list.item.selector : '[data-list-item]',
            },
            selection: {
                selector: this.utils.hasProperty('list.item.selection.selector', options) ? options.list.item.selector : 'items',
                itemId: this.utils.hasProperty('list.item.selection.itemId', options) ? options.list.item.selector : 'selection-item-',
                actionsClass: this.utils.hasProperty('list.item.selection.actionsClass', options) ? options.list.item.actionsClass : '[data-crud-action-multiple]',
            },
            delete: {
                selector: {
                    button: this.utils.hasProperty('list.delete.selector.button', options) ? options.list.delete.selector.button : '[delete-action]'
                },
                data: {
                    confirm: this.utils.hasProperty('list.delete.data.confirm', options) ? options.list.delete.data.confirm : '[delete-confirm-label]'
                },
            }
        };
    }

    /**
     * Init the builder
     *
     * @param options
     */
    init(options)
    {
        var self = this;
        self.config(options);
        self.initGlobalEvents();
    }

    initGlobalEvents()
    {
        var self = this;

        $(self.options.item.selector).each(function() {
            var element = $(this);
            self.initSelectionItem(element);
            self.initDeleteItemEvent(element);
            self.initSelectItemEvent(element);
        });
    }

    initDeleteItemEvent(element) {
        var self            = this;
        var deleteElement   = $(self.options.delete.selector.button);
        var deleteConfirm   = $(deleteElement).data(self.options.delete.data.confirm);

        $(deleteElement).off('click').on('click', function () {
            if (confirm(deleteConfirm)) {
                return true;
            }

            return false;
        });
    }

    initSelectItemEvent(element) {
        var self          = this;
        var id            = element.data('id');
        var selectionItem = $('#' + self.options.selection.itemId + id);

        $(selectionItem).unbind('change').change(function() {
            if ($(this).prop('checked')) {
                self.addItemSelected(element);
            } else {
                self.removeItemFromSelection(element);
            }
        });
    }

    initSelectionItem(element) {
        var self          = this;
        var id            = element.data('id');
        var selectionItem = $('#' + self.options.selection.itemId + id);

        if (selectionItem.prop('checked')) {
            self.addItemSelected(element);
        } else {
            self.removeItemFromSelection(element);
        }
    }

    addItemSelected(element) {
        if (!this.isItemExist(element)) {
            this.itemsSelected.push(element);
            $(this.options.selection.actionsClass).removeAttr('disabled');
        }
    }

    isItemExist(item) {
        var current = this;
        for (var i = current.itemsSelected.length - 1; i >= 0; i--) {
            if (current.itemsSelected[i].data('id') == item.data('id')) {
               return true;
            }
        }

        return false;
    }

    resetItems() {
        this.itemsSelected = [];
        $(this.options.selection.actionsClass).attr('disabled', 'disabled');
    }

    addAllItems () {
        var self = this;

        $(self.options.item.selector).each(function (index, element) {
            self.addItemSelected($(element));
        });
    }

    removeItemFromSelection(element) {
        var self      = this;
        var tempArray = [];
        self.itemsSelected.forEach(function (current, index) {
            if (element.data('id') != current.data('id')) {
                tempArray.push(current);
            }
        });
        self.itemsSelected = tempArray;
        if (self.itemsSelected.length <= 0) {
            self.resetItems();
        }
    }

    removeItem (element)
    {
        $(element).remove();
    }

    checkAll() {
        $('[id^="' + this.options.selection.itemId + '"]').prop('checked', true);
        this.addAllItems();
    }

    uncheckAll() {
        $('[id^="' + this.options.selection.itemId + '"]').prop('checked', false);
        this.resetItems();
    }


    getItemsSelectedId ()
    {
        var self = this;
        var ids  = [];
        self.itemsSelected.forEach(function (item, index) {
            ids.push(item.data('id'));
        });

        return ids;
    }

}

export default ListManager;
